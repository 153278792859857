const btn = document.querySelector('.scrol-btn__title');
const btnWrapper = document.querySelector('.wrapper')
const containerToScroll = document.querySelector('#projects');
const topPos = containerToScroll.offsetTop;
window.addEventListener('scroll', onScroll);
const minimumPxToScroll = 500;
function onScroll() {
 
  if (topPos > minimumPxToScroll) {
    btnWrapper.classList.remove('animation-scroll')
  }
}

 function toAnchor() {
    btnWrapper.classList.add('animation-scroll')
    setTimeout(()=> window.scrollTo(0, topPos), 1000)
  
}

btn.addEventListener('click', toAnchor);

// (function() {
//     'use strict';
//     var btnScrollDown = document.querySelector('.scrol-btn__title');
//     function scrollDown() {
//       var windowCoords = document.documentElement.clientHeight;
//       (function scroll() {
//         if (window.pageYOffset < windowCoords) {
//           window.scrollBy(0, 10);
//           setTimeout(scroll, 10);
//         }
//         if (window.pageYOffset > windowCoords) {
//           window.scrollTo(0, windowCoords);
//         }
//       })();
//     }
//     btnScrollDown.addEventListener('click', scrollDown);
//   })();


// $('.scrol-btn__title').on('click', function() {
//     var el = $(this);
//     var $scroll = $('.wrapper');
//     var dest = el.attr('target'); 
//     var minimumPxToScroll = 500;
//     if (dest !== undefined && dest !== '') { 
//         $scroll.addClass('animation-scroll');
//         $('html').animate({
//           scrollTop: $(dest).offset().top 
//         }, 1000 
//       );
//       if ($(window).scrollTop() > minimumPxToScroll) {
//         $scroll.removeClass('animation-scroll');
//     }
//     }
//     return false;
//   });