$(document).ready(function () {
  $('.slider').slick({
    arrows: false,
    dots: false,
    slidesToShow:4,
    draggable:false, 
    slidesToScroll:4,
    autoplay:false,
    responsive: [
    
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
          autoplaySpeed: 5000,
         
        },
      },
   
      
    ],
    customPaging: function () {
      return `<button class="slick-dots__inner" type="button"></button>`;
    },
  });
});
