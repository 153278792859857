$(document).ready(function () {
  $('.slider-modal').slick({
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5500,
    adaptiveHeight: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: true,
        },
      },
    ],
    customPaging: function () {
      return `<button class="slick-dots__inner" type="button"></button>`;
    },
  });
});
